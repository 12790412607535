import React from 'react'
import PropTypes from 'prop-types'

const Hourglass = ({ className }) => (
	<svg
		className={className || 'w-6 h-6'}
		viewBox="0 0 24 24"
		height="24"
		width="24"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fill="none"
			stroke="#000"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth="1.5"
			d="M6.038,5.478C6.594,8.687,8.548,10.885,12,12c3.452-1.115,5.406-3.313,5.962-6.522A1.5,1.5,0,0,0,16.471,3.75H7.529A1.5,1.5,0,0,0,6.038,5.478Z"
			transform="undefined"
		/>
		<path
			fill="none"
			stroke="#000"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth="1.5"
			d="M3.75 0.75L20.25 0.75"
			transform="undefined"
		/>
		<path
			fill="none"
			stroke="#000"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth="1.5"
			d="M7.529,20.25a1.5,1.5,0,0,1-1.491-1.727C6.594,15.314,8.548,13.116,12,12c3.452,1.116,5.406,3.314,5.962,6.523a1.5,1.5,0,0,1-1.491,1.727Z"
			transform="undefined"
		/>
		<path
			fill="none"
			stroke="#000"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth="1.5"
			d="M3.75 23.25L20.25 23.25"
			transform="undefined"
		/>
	</svg>
)

Hourglass.propTypes = {
	className: PropTypes.string
}

export default Hourglass
