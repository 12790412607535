import {
	SET_UI_VALUE
} from '../actions/actionTypes'

const initialState = {
	statusExpanded: false,
	processingFiles: [],
	runCheck: false,
	checkStarted: false,
	processingFilesInitialized: false,
	logInterval: null
}

const reducer = (state = initialState, action) => {
	switch (action.type) {
	case SET_UI_VALUE:
		return {
			...state,
			[action.payload.name]: action.payload.value
		}
	default:
		return state
	}
}

export default reducer
