import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import axios from 'axios'
import Loader from '../Loader'
import UserItem from './components/UserItem'
import Modal from '../Modal'
import Tabs from '../Tabs'
import UserGroupIcon from '../../assets/icons/UserGroupIcon'
import UserAddIcon from '../../assets/icons/UserAddIcon'
import UserForm from './components/UserForm'
import { DEFAULT_ERROR } from '../../utils/const'

const UserModal = ({ isShown, handleClose, loadingUsers, users, handleAddUser }) => {
	const emptyUserData = {
		username: '',
		email: '',
		password: '',
		repeat_password: '',
		is_active: false
	}

	const [activeTab, setSelectedTab] = useState(0)
	const [userFormData] = useState(emptyUserData)
	const [disableButton, setDisableButton] = useState(false)
	const [roles, setRoles] = useState([])
	const [errors, setErrors] = useState([])
	const user = useSelector(state => state.user.auth)

	useEffect(() => {
		if (user) {
			if (roles.length === 0) {
				getRoles()
			}
		}
	}, [])

	const getRoles = () => {
		axios.get(`${process.env.REACT_APP_API_URL}/api/roles`)
			.then(response => {
				setRoles(response.data)
				console.log('roles', response.data)
			})
			.catch(error => {
				console.log('error', error)
			})
	}

	const saveUser = data => {
		setDisableButton(true)
		const d = {
			username: data.username,
			email: data.email,
			password: data.password,
			password_confirmation: data.repeat_password,
			roles: roles.filter(item => data[item.role]).map(item => item.id),
			is_active: data.is_active
		}
		axios.post(`${process.env.REACT_APP_API_URL}/api/users`, d, {
			headers: {
				Authorization: `Bearer ${user.access_token}`
			}
		})
			.then(response => {
				setDisableButton(false)
				handleAddUser(response.data)
				setErrors([])
				setSelectedTab(0)
			})
			.catch(error => {
				setDisableButton(false)
				try {
					if (error.response.status === 400 || error.response.status === 500) {
						setErrors(error.response.data)
					} else {
						setErrors([DEFAULT_ERROR])
					}
				} catch (error) {
					setErrors([DEFAULT_ERROR])
				}
			})
	}

	const getModalContent = () => {
		switch (activeTab) {
		case 0:
			return (
				<React.Fragment>
					{
						loadingUsers
							? (
								<Loader />
							)
							: (
								<div>
									{
										users.map((item, index) => (
											<UserItem key={index} user={item} />
										))
									}
								</div>
							)
					}
				</React.Fragment>
			)
		case 1:
			return (
				<UserForm
					user={userFormData}
					onSubmit={saveUser}
					disableButton={disableButton}
					buttonLabel="Snimi"
					roles={roles}
					responseErrors={errors}
				/>
			)
		default:
			return null
		}
	}

	return (
		<Modal
			isShown={isShown}
			handleClose={handleClose}
			title="Korisnici"
			content={
				<div>
					<div className="mb-4">
						<Tabs
							activeTab={activeTab}
							tabs={[
								{
									name: 'Korisnici',
									icon: UserGroupIcon
								},
								{
									name: 'Dodaj korisnika',
									icon: UserAddIcon
								}
							]}
							handleChangeTab={setSelectedTab}
						/>
					</div>
					{getModalContent()}
				</div>
			}
		/>
	)
}

UserModal.propTypes = {
	isShown: PropTypes.bool,
	handleClose: PropTypes.func,
	loadingUsers: PropTypes.bool,
	users: PropTypes.array,
	handleAddUser: PropTypes.func
}

export default UserModal
