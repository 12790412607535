import React from 'react'
import PropTypes from 'prop-types'
import InformationCircleIcon from '../../../assets/icons/solid/InformationCircleIcon'

const Input = ({
	id,
	label,
	type,
	value,
	defaultValue,
	placeholder = '',
	handleChange,
	register,
	required = '',
	pattern = null,
	errors = null,
	minLength = null,
	validate = null,
	trigger = null
}) => {
	const getInputClassnames = () => {
		try {
			if (errors[id]) {
				return 'block w-full pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md'
			}
		} catch (error) {}
		return 'shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md'
	}

	const getErrorsIcon = () => {
		try {
			if (errors[id] && type !== 'hidden') {
				return (
					<div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
						<InformationCircleIcon className="h-5 w-5 text-red-500" />
					</div>
				)
			}
		} catch (error) {}
		return null
	}

	const getErrorMessage = () => {
		try {
			if (errors[id]) {
				return (
					<p className="mt-2 text-sm text-red-600" id={id}>{errors[id].message}</p>
				)
			}
		} catch (error) {}
	}

	const getInputField = () => {
		switch (type) {
		case 'hidden':
			return (
				<input
					type="text"
					name={id}
					id={id}
					value={value}
					onChange={trigger}
					className="hidden"
					ref={
						register({
							required
						})
					}
				/>
			)
		case 'checkbox':
			return (
				<React.Fragment>
					<input
						id={id}
						name={id}
						type={type}
						className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
						ref={register}
					/>
				</React.Fragment>
			)
		default:
			return (
				<React.Fragment>
					<input
						type={type}
						name={id}
						id={id}
						{ ...value && { value } }
						{ ...defaultValue && { defaultValue } }
						{ ...handleChange && { onChange: event => handleChange(event.target.name, event.target.value) } }
						className={getInputClassnames()}
						placeholder={placeholder}
						{
							...register && {
								ref: register({
									required,
									pattern,
									minLength,
									validate
								})
							}
						}
					/>
					{getErrorsIcon()}
				</React.Fragment>
			)
		}
	}

	const getContent = () => {
		switch (type) {
		case 'checkbox':
			return (
				<React.Fragment>
					<div>
						<div className="flex items-start">
							<div className="h-5 flex items-center">
								{getInputField()}
							</div>
							<div className="ml-3 text-sm">
								<label htmlFor={id} className="font-medium text-gray-700">{label}</label>
							</div>
						</div>
					</div>
				</React.Fragment>
			)
		default:
			return (
				<React.Fragment>
					{
						label && (
							<label htmlFor={id} className="block text-sm font-medium text-gray-700">{label}</label>
						)
					}
					<div className="mt-1 relative">
						{getInputField()}
					</div>
				</React.Fragment>
			)
		}
	}

	return (
		<React.Fragment>
			{getContent()}
			{getErrorMessage()}
		</React.Fragment>
	)
}

Input.propTypes = {
	id: PropTypes.string,
	label: PropTypes.string,
	type: PropTypes.string,
	value: PropTypes.string,
	defaultValue: PropTypes.string,
	placeholder: PropTypes.string,
	handleChange: PropTypes.func,
	register: PropTypes.func,
	required: PropTypes.string,
	pattern: PropTypes.object,
	errors: PropTypes.object,
	minLength: PropTypes.object,
	validate: PropTypes.func,
	trigger: PropTypes.func
}

export default Input
