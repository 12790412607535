import React, { useEffect } from 'react'
import axios from 'axios'
import { useSelector, useDispatch } from 'react-redux'
import { setUserValue } from '../../store/actions/user'

const RefreshToken = () => {
	const auth = useSelector(state => state.user.auth)
	const dispatch = useDispatch()

	useEffect(() => {
		setTimeout(() => {
			refreshToken()
		}, 30 * 60 * 1000)
	}, [])

	const refreshToken = () => {
		axios.post(`${process.env.REACT_APP_API_URL}/api/auth/refresh`, null, {
			headers: {
				Authorization: `Bearer ${auth.access_token}`
			}
		})
			.then(response => {
				dispatch(setUserValue('auth', response.data))
			})
			.catch(error => {
				console.log('error', error)
			})
	}

	return <div />
}

export default RefreshToken
