import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import Input from '../../../common/Input'
import Notification from '../../../../components/Notification'
import { EMAIL_PATTERN } from '../../../../utils/const'

const UserForm = ({ user, onSubmit, disableButton, buttonLabel, roles, responseErrors }) => {
	const { register, errors, handleSubmit, watch } = useForm()
	const password = useRef()
	password.current = watch('password', '')

	return (
		<div>
			<form method="post" onSubmit={handleSubmit(onSubmit)}>
				<div className="w-full mb-4">
					<Input
						type="text"
						id="username"
						label="Korisničko ime"
						defaultValue={user.username}
						register={register}
						required="Korisničko ime je obavezno."
						errors={errors}
					/>
				</div>
				<div className="w-full mb-4">
					<Input
						type="email"
						id="email"
						label="Email adresa"
						defaultValue={user.email}
						placeholder="email@domen.com"
						register={register}
						required="Email adresa je obavezna."
						pattern={{
							value: EMAIL_PATTERN,
							message: 'Email adresa nije u ispravnom formatu.'
						}}
						errors={errors}
					/>
				</div>
				<div className="w-full mb-4">
					<Input
						type="password"
						id="password"
						label="Šifra"
						defaultValue={user.password}
						register={register}
						required="Šifra je obavezna."
						minLength={{
							value: 6,
							message: 'Šifra mora imati najmanje 6 karaktera.'
						}}
						errors={errors}
					/>
				</div>
				<div className="w-full mb-4">
					<Input
						type="password"
						id="repeat_password"
						label="Ponovite šifru"
						defaultValue={user.repeat_password}
						register={register}
						required="Ponovljena šifra je obavezna."
						validate={
							value => value === watch('password') || 'Šifre moraju da se podudaraju.'
						}
						errors={errors}
					/>
				</div>
				<div className="mb-4">
					<p className="text-lg mb-2">Uloge</p>
					{
						roles.map((item, index) => (
							<div key={index} className="mb-2">
								<Input
									id={item.role}
									type="checkbox"
									label={item.name}
									register={register}
								/>
							</div>
						))
					}
				</div>
				<div className="mb-4">
					<p className="text-lg mb-2">Status</p>
					<div>
						<Input
							id="is_active"
							type="checkbox"
							label="Aktivan"
							register={register}
						/>
					</div>
				</div>
				{
					responseErrors.length > 0 && (
						<div className="mb-3">
							<Notification
								type="error"
								title="Dogodila se greška"
								message={
									responseErrors.map((item, index) => (
										<React.Fragment key={index}>
											<span>{item}</span><br />
										</React.Fragment>
									))
								}
							/>
						</div>
					)
				}
				<div>
					<button
						type="submit"
						className={`${disableButton ? 'cursor-not-allowed bg-gray-200 text-gray-400' : 'text-white bg-splab-800 hover:bg-splab-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'} flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium `}
						disabled={disableButton}
					>
						{buttonLabel}
					</button>
				</div>
			</form>
		</div>
	)
}

UserForm.propTypes = {
	user: PropTypes.object,
	onSubmit: PropTypes.func,
	disableButton: PropTypes.bool,
	buttonLabel: PropTypes.string,
	roles: PropTypes.array,
	responseErrors: PropTypes.array
}

export default UserForm
