import React from 'react'
import PropTypes from 'prop-types'
import CheckCircleIcon from '../../../../assets/icons/CheckCircleIcon'
import CloseCircleIcon from '../../../../assets/icons/CloseCircleIcon'

const UserItem = ({ user }) => {
	return (
		<div className="bg-white shadow overflow-hidden sm:rounded-md">
			<ul className="divide-y divide-gray-200">
				<li>
					<div className="flex items-center px-4 py-4 sm:px-6">
						<div className="min-w-0 flex-1 flex items-center">
							<div className="w-full flex justify-between">
								<div>
									<p className="text-sm font-medium text-indigo-600 truncate">
										{user.username} <span className="text-gray-500">({user.email})</span>
									</p>
									<p className="mt-2 flex items-center text-sm text-gray-500">
										{user.roles.map(item => item.role).join(', ')}
									</p>
								</div>
								<div className="hidden md:block">
									<div className="flex items-center h-full">
										<p className="mr-2 flex items-center text-sm text-gray-500">
											{
												user.is_active
													? (
														<React.Fragment>
															<CheckCircleIcon className="w-6 h-6 text-green-500 mr-1" />
															Aktivan
														</React.Fragment>
													)
													: (
														<React.Fragment>
															<CloseCircleIcon className="w-6 h-6 text-red-500 mr-1" />
															Nije aktivan
														</React.Fragment>
													)
											}
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</li>
			</ul>
		</div>
	)
}

UserItem.propTypes = {
	user: PropTypes.object
}

export default UserItem
