import React from 'react'
import PropTypes from 'prop-types'
import { Transition } from '@headlessui/react'
import CloseIcon from '../../assets/icons/CloseIcon'

const Modal = ({ isShown, handleClose, title, content }) => (
	<React.Fragment>
		<Transition
			show={isShown}
			enter="ease-out duration-300"
			enterFrom="opacity-0"
			enterTo="opacity-100"
			leave="ease-in duration-200"
			leaveFrom="opacity-100"
			leaveTo="opacity-0"
		>
			{
				ref => (
					<div ref={ref} className="fixed z-10 inset-0 overflow-y-auto">
						<div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
							<div
								className="fixed inset-0 transition-opacity"
								aria-hidden="true"
								onClick={handleClose}
							>
								<div className="absolute inset-0 bg-black opacity-50" />
							</div>
							<span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
							<Transition
								show={isShown}
								enter="ease-out duration-300 delay-200"
								enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
								enterTo="opacity-100 translate-y-0 sm:scale-100"
								leave="ease-in duration-200 delay-200"
								leaveFrom="opacity-100 translate-y-0 sm:scale-100"
								leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							>
								{
									modalRef => (
										<div
											ref={modalRef}
											className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all lg:w-2/4 sm:my-8 sm:align-middle sm:w-full sm:p-6"
											role="dialog"
											aria-modal="true"
											aria-labelledby="modal-headline"
										>
											<div className="bg-white py-1 border-b border-gray-200 mb-5">
												<div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap">
													<div className="ml-4 mt-2">
														<h3 className="text-lg leading-6 font-medium text-gray-900">
															{title}
														</h3>
													</div>
													<div className="ml-4 mt-2 flex-shrink-0">
														<button
															className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
															onClick={handleClose}
														>
															<span className="sr-only">Close panel</span>
															<CloseIcon />
														</button>
													</div>
												</div>
											</div>
											<div>
												{content}
											</div>
										</div>
									)
								}
							</Transition>
						</div>
					</div>
				)
			}
		</Transition>
	</React.Fragment>
)

Modal.propTypes = {
	isShown: PropTypes.bool,
	handleClose: PropTypes.func,
	title: PropTypes.string,
	content: PropTypes.element
}

export default Modal
