import React from 'react'
import PropTypes from 'prop-types'

const CheckIcon = ({ className }) => (
	<svg
		className={className || 'w-6 h-6'}
		fill="none"
		stroke="currentColor"
		viewBox="0 0 24 24"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth="2" d="M5 13l4 4L19 7"
		/>
	</svg>
)

CheckIcon.propTypes = {
	className: PropTypes.string
}

export default CheckIcon
