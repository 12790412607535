import {
	SET_USER_VALUE
} from './actionTypes'

export const setUserValue = (name, value) => {
	return dispatch => {
		return new Promise((resolve, reject) => {
			dispatch({
				type: SET_USER_VALUE,
				payload: {
					name,
					value
				}
			})
			resolve()
		})
	}
}
