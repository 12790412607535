import { createStore, combineReducers, compose, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import UIReducer from './reducers/ui'
import UserReducer from './reducers/user'

const rootReducer = combineReducers({
	ui: UIReducer,
	user: UserReducer
})

const composeEnhancers = compose

const configureStore = () => {
	return createStore(rootReducer, composeWithDevTools(composeEnhancers(applyMiddleware(thunk))))
}

export default configureStore
