import React from 'react'
import PropTypes from 'prop-types'

const Tabs = ({ activeTab, tabs, handleChangeTab }) => {
	const changeTab = (event, tab) => {
		event.stopPropagation()
		event.preventDefault()
		handleChangeTab(tab)
	}

	return (
		<div>
			<div className="sm:hidden">
				<label htmlFor="tabs" className="sr-only">Select a tab</label>
				<select
					id="tabs"
					name="tabs"
					className="block w-full focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
					value={activeTab}
					onChange={
						event => handleChangeTab(Number(event.target.value))
					}
				>
					{
						tabs.map((item, index) => (
							<option
								key={index}
								value={index}
							>
								{item.name}
							</option>
						))
					}
				</select>
			</div>
			<div className="hidden sm:block">
				<div className="border-b border-gray-200">
					<nav className="-mb-px flex space-x-8" aria-label="Tabs">
						{
							tabs.map((item, index) => (
								<a
									key={index}
									href="#"
									onClick={
										event => changeTab(event, index)
									}
									className={`${activeTab === index ? 'border-indigo-500 text-indigo-600' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'} group inline-flex items-center py-4 px-1 border-b-2 font-medium text-sm`}
								>
									{
										item.icon && (
											React.createElement(
												item.icon,
												{
													className: `${activeTab === index ? 'border-indigo-500' : 'text-gray-400 group-hover:text-gray-500'} -ml-0.5 mr-2 h-5 w-5`
												}
											)
										)
									}
									<span>{item.name}</span>
								</a>
							))
						}
					</nav>
				</div>
			</div>
		</div>
	)
}

Tabs.propTypes = {
	activeTab: PropTypes.number,
	tabs: PropTypes.array,
	handleChangeTab: PropTypes.func
}

export default Tabs
