import React from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import Login from './pages/Login'
import Home from './pages/Home'

const App = () => (
	<div>
		<BrowserRouter>
			<Switch>
				<Route exact path="/">
					<Login />
				</Route>
				<Route exact path="/reports">
					<Home />
				</Route>
			</Switch>
		</BrowserRouter>
	</div>
)

export default App
