import {
	SET_USER_VALUE
} from '../actions/actionTypes'

const initialState = {
	auth: null,
	userData: null
}

const reducer = (state = initialState, action) => {
	switch (action.type) {
	case SET_USER_VALUE:
		return {
			...state,
			[action.payload.name]: action.payload.value
		}
	default:
		return state
	}
}

export default reducer
