import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import axios from 'axios'
import numeral from 'numeral'
import { format, getTime } from 'date-fns'
import _ from 'lodash'
import LogoutIcon from '../../assets/icons/LogoutIcon'
import UserGroupIcon from '../../assets/icons/UserGroupIcon'
import RefreshIcon from '../../assets/icons/RefreshIcon'
import UserModal from '../../components/UserModal'
import Loader from '../../components/Loader'
import RefreshToken from '../../components/RefreshToken'
import NextGenAppsSignature from '../../components/NextGenAppsSignature'
import { setUserValue } from '../../store/actions/user'
import Logo from '../../assets/img/logo-sp-laborotorija.png'
import Tabs from '../../components/common/Tabs'
import reportColumns from '../../data/reportColumns.json'
import Hourglass from '../../assets/icons/Hourglass'
import CloseIcon from '../../assets/icons/CloseIcon'
import CheckIcon from '../../assets/icons/CheckIcon'
import CheckCircleIcon from '../../assets/icons/CheckCircleIcon'
import pagination from '../../utils/simple-pagination'
import ArrowLeft from '../../assets/icons/ArrowLeft'
import ArrowRight from '../../assets/icons/ArrowRight'
import Input from '../../components/common/Input'

const Home = () => {
	const [redirectToLogin, setRedirectToLogin] = useState(false)
	const [disableLogoutButton, setDisableLogoutButton] = useState(false)
	const [showUsersModal, setShowUsersModal] = useState(false)
	const [loadingUsers, setLoadingUsers] = useState(false)
	const [users, setUsers] = useState([])
	const [loadingFiles, setLoadingFiles] = useState(false)
	const [files, setFiles] = useState([])
	const [selectedTab, setSelectedTab] = useState(0)
	// eslint-disable-next-line no-unused-vars
	const [reports, setReports] = useState([])
	const [loadingReports, setLoadingReports] = useState(false)
	const [currentPage, setCurrentPage] = useState(1)
	const [filteredReports, setFilteredReports] = useState([])
	const [pageList, setPageList] = useState(null)
	const [search, setSearch] = useState({
		from: '',
		to: '',
		keyword: ''
	})

	const auth = useSelector(store => store.user.auth)
	const userData = useSelector(store => store.user.userData)

	const dispatch = useDispatch()
	const reportsPerPage = 50

	useEffect(() => {
		if (!auth || !userData) {
			setRedirectToLogin(true)
		}
		if (auth) {
			getFiles()
			if (isSpReport()) {
				getReports()
			}
			setCurrentPage(1)
		}
	}, [])

	useEffect(() => {
		if (!auth && !userData) {
			setRedirectToLogin(true)
		}
	}, [auth, userData])

	useEffect(() => {
		setPageList(pagination(1, Math.ceil(filteredReports.length / reportsPerPage)))
	}, [filteredReports])

	const getReports = () => {
		setLoadingReports(true)
		axios.get(`${process.env.REACT_APP_API_URL}/api/reports`, {
			headers: {
				Authorization: `Bearer ${auth.access_token}`
			}
		})
			.then(response => {
				setLoadingReports(false)
				setReports(response.data)
				setFilteredReports(response.data)
			})
			.catch(() => {
				setLoadingReports(false)
			})
	}

	const getFiles = () => {
		setLoadingFiles(true)
		axios.get(`${process.env.REACT_APP_API_URL}/api/files`, {
			headers: {
				Authorization: `Bearer ${auth.access_token}`
			}
		})
			.then(response => {
				setLoadingFiles(false)
				setFiles(_.orderBy(response.data, ['last_modified'], 'desc'))
			})
			.catch(() => {
				setLoadingFiles(false)
			})
	}

	const logout = () => {
		setDisableLogoutButton(true)
		axios.post(`${process.env.REACT_APP_API_URL}/api/auth/logout`, null, {
			headers: {
				Authorization: `Bearer ${auth.access_token}`
			}
		})
			.then(() => {
				dispatch(setUserValue('auth', null))
				dispatch(setUserValue('userData', null))
			})
			.catch(() => {
				setDisableLogoutButton(false)
			})
	}

	const isAdmin = () => {
		try {
			if (
				userData.roles.findIndex(item => item.role === 'administrator') > -1 ||
				userData.roles.findIndex(item => item.role === 'admin-users') > -1
			) {
				return true
			}
		} catch (error) { }
		return false
	}

	const isSpReport = () => {
		try {
			if (
				userData.roles.findIndex(item => item.role === 'sp-report') > -1
			) {
				return true
			}
		} catch (error) { }
		return false
	}

	const openUsersModal = () => {
		setShowUsersModal(true)
		setLoadingUsers(true)
		axios.get(`${process.env.REACT_APP_API_URL}/api/users`, {
			headers: {
				Authorization: `Bearer ${auth.access_token}`
			}
		})
			.then(response => {
				setLoadingUsers(false)
				setUsers(response.data)
			})
			.catch(() => {
				setLoadingUsers(false)
			})
	}

	const addUserToState = user => {
		setUsers([...users, user])
	}

	const getReportField = (report, column, index) => {
		let value = report[column.property]
		try {
			if (value) {
				if (column.type === 'date') {
					value = format(new Date(value), column.format)
				} else if (column.type === 'icon') {
					if (value === 32) {
						value = ''
					} else if (value === 54) {
						value = (
							<div className="flex justify-center">
								<Hourglass />
							</div>
						)
					} else if (value === 251) {
						value = (
							<div className="flex justify-center">
								<CloseIcon />
							</div>
						)
					} else if (value === 252) {
						value = (
							<div className="flex justify-center">
								<CheckIcon />
							</div>
						)
					} else if (value === 254) {
						value = (
							<div className="flex justify-center">
								<CheckCircleIcon />
							</div>
						)
					}
				}
			}
		} catch (error) {}
		return (
			<td
				key={index}
				className={`px-3 py-2 text-sm font-medium text-gray-900 relative ${column.className && column.className}`}
			>
				{value}
			</td>
		)
	}

	const getFilteredReports = () => {
		return _.take(_.drop(filteredReports, (currentPage - 1) * reportsPerPage), reportsPerPage)
	}

	const changePage = (event, page) => {
		if (event) {
			event.stopPropagation()
			event.preventDefault()
		}
		if (currentPage !== page) {
			setCurrentPage(page)
			setPageList(pagination(page, Math.ceil(filteredReports.length / reportsPerPage)))
		}
	}

	const prevPage = event => {
		event.stopPropagation()
		event.preventDefault()
		if (currentPage > 1) {
			changePage(null, currentPage - 1)
		}
	}

	const nextPage = event => {
		event.stopPropagation()
		event.preventDefault()
		if (currentPage < Math.ceil(filteredReports.length / reportsPerPage)) {
			changePage(null, currentPage + 1)
		}
	}

	const changeSearchValue = (name, value) => {
		setSearch({
			...search,
			[name]: value
		})
	}

	const searchReports = event => {
		event.preventDefault()
		event.stopPropagation()
		let newFilteredReports = [...reports]
		if (search.from) {
			const from = getTime(new Date(search.from))
			newFilteredReports = newFilteredReports.filter(item => _.gte(item.date_of_request_timestamp * 1000, from))
		}
		if (search.to) {
			const to = getTime(new Date(search.to))
			newFilteredReports = newFilteredReports.filter(item => _.lte(item.date_of_request_timestamp * 1000, to))
		}
		if (search.keyword) {
			const keyword = search.keyword.toLowerCase()
			newFilteredReports = newFilteredReports.filter(item => item.production_order.toLowerCase().includes(keyword) || item.sample_order.toLowerCase().includes(keyword) || item.sample_name.toLowerCase().includes(keyword) || item.client.toLowerCase().includes(keyword) || item.user.toLowerCase().includes(keyword))
		}
		setFilteredReports(newFilteredReports)
	}

	const getLastUpdated = d => {
		if (d) {
			const date = new Date(d)
			const newDate = new Date(
				date.getUTCFullYear(),
				date.getUTCMonth(),
				date.getUTCDate(),
				date.getUTCHours(),
				date.getUTCMinutes(),
				date.getUTCSeconds()
			)
			return format(newDate, 'dd.MM.yyyy. HH:mm')
		}
	}

	return (
		<React.Fragment>
			{
				redirectToLogin && (
					<Redirect to="/" />
				)
			}
			{
				auth && (
					<React.Fragment>
						<div className="px-4 py-5 sm:p-6">
							<div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
								<div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap">
									<div className="ml-4 mt-2">
										<img
											src={Logo}
											alt="sp laboratorija logo"
											className="max-w-xs"
										/>
									</div>
									<div className="ml-4 mt-2 flex-shrink-0">
										<button
											type="button"
											className={`${loadingFiles || loadingReports ? 'cursor-not-allowed bg-gray-200 text-gray-400' : 'text-white bg-yellow-400 hover:bg-yellow-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'} relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md`}
											disabled={loadingFiles}
											onClick={selectedTab === 0 ? getFiles : getReports}
										>
											<RefreshIcon className="w-6 h-6 mr-1" />
											Učitaj ponovo
										</button>
										<button
											type="button"
											className={`${disableLogoutButton ? 'cursor-not-allowed bg-gray-200 text-gray-400' : 'text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'} relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md ml-3`}
											disabled={disableLogoutButton}
											onClick={logout}
										>
											<LogoutIcon className="w-6 h-6 mr-1" />
											Odjavi se
										</button>
										{
											isAdmin() && (
												<button
													type="button"
													className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ml-3"
													onClick={openUsersModal}
												>
													<UserGroupIcon className="w-6 h-6 mr-1" />
													Korisnici
												</button>
											)
										}
									</div>
								</div>
							</div>
							<div>
								{
									isSpReport() && (
										<Tabs
											tabs={['Fajlovi', 'Izveštaji']}
											selected={selectedTab}
											onChange={setSelectedTab}
										/>
									)
								}
								{
									selectedTab === 0 && (
										<div>
											{
												loadingFiles
													? (
														<Loader />
													)
													: (
														<div>
															<table className="w-full divide-y divide-gray-200">
																<thead className="bg-gray-200">
																	<tr>
																		<th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
																			Naziv
																		</th>
																		<th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
																			Veličina
																		</th>
																		<th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
																			Datum
																		</th>
																		<th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
																			Preuzimanje
																		</th>
																	</tr>
																</thead>
																<tbody>
																	{
																		files.map((item, index) => (
																			<tr key={index} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
																				<td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
																					{item.name}
																				</td>
																				<td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
																					{numeral(item.size).format('0.0 b')}
																				</td>
																				<td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
																					{format(new Date(item.last_modified * 1000), 'd/M/yyyy')}
																				</td>
																				<td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
																					<a
																						href={`${process.env.REACT_APP_API_URL}/api/files/download/${item.name}?token=${auth.access_token}`}
																						target="_blank"
																						rel="noreferrer"
																						className="text-indigo-600 hover:text-indigo-900"
																					>
																						Preuzmi
																					</a>
																				</td>
																			</tr>
																		))
																	}
																</tbody>
															</table>
														</div>
													)
											}
										</div>
									)
								}
								{
									selectedTab === 1 && (
										<div className="relative">
											{
												loadingReports
													? (
														<Loader />
													)
													: (
														<div>
															{
																reports.length > 0 && (
																	<p className="text-lg mb-2">Ažurirano: {getLastUpdated(reports[0].report_created)}</p>
																)
															}
															<form
																action="#"
																method="POST"
																onSubmit={searchReports}
															>
																<div className="flex mb-3">
																	<div className="grid grid-cols-3 gap-6 flex-1 mr-6">
																		<div>
																			<Input
																				id="from"
																				label="Od"
																				type="date"
																				handleChange={changeSearchValue}
																			/>
																		</div>
																		<div>
																			<Input
																				id="to"
																				label="Do"
																				type="date"
																				handleChange={changeSearchValue}
																			/>
																		</div>
																		<div>
																			<Input
																				id="keyword"
																				label="Ključna reč"
																				type="text"
																				handleChange={changeSearchValue}
																			/>
																		</div>
																	</div>
																	<div className="flex items-end">
																		<button
																			type="submit"
																			className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
																		>
																			Pretraži
																		</button>
																	</div>
																</div>
															</form>
															<table className="min-w-full divide-y divide-gray-200 relative">
																<thead className="bg-gray-50">
																	<tr className="bg-gray-200">
																		{
																			reportColumns.map((item, index) => (
																				<th key={index} className={`px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider sticky top-0 z-20 bg-gray-200 ${item.className}`}>
																					{item.name}
																				</th>
																			))
																		}
																	</tr>
																</thead>
																<tbody>
																	{
																		getFilteredReports().map((item, index) => (
																			<tr key={index} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
																				{
																					reportColumns.map((col, colindex) => getReportField(item, col, colindex))
																				}
																			</tr>
																		))
																	}
																</tbody>
															</table>
															<nav className="border-t border-gray-200 px-4 flex items-center justify-between sm:px-0">
																<div className="-mt-px w-0 flex-1 flex">
																	<a
																		href="#"
																		className="border-t-2 border-transparent pt-4 pr-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300"
																		onClick={
																			event => prevPage(event)
																		}
																	>
																		<ArrowLeft className="mr-3 h-5 w-5 text-gray-400" />
																		Previous
																	</a>
																</div>
																<div className="hidden md:-mt-px md:flex">
																	{
																		pageList.map((item, index) => (
																			<a
																				key={index}
																				href="#"
																				className={`${currentPage === item ? 'border-indigo-500 text-indigo-600 cursor-default' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'} border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium`}
																				onClick={
																					event => changePage(event, item)
																				}
																			>
																				{item}
																			</a>
																		))
																	}
																</div>
																<div className="-mt-px w-0 flex-1 flex justify-end">
																	<a
																		href="#"
																		className="border-t-2 border-transparent pt-4 pl-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300"
																		onClick={
																			event => nextPage(event)
																		}
																	>
																		Next
																		<ArrowRight className="ml-3 h-5 w-5 text-gray-400" />
																	</a>
																</div>
															</nav>
														</div>
													)
											}
										</div>
									)
								}
							</div>
						</div>
						<NextGenAppsSignature />
						<UserModal
							isShown={showUsersModal}
							handleClose={
								() => setShowUsersModal(false)
							}
							loadingUsers={loadingUsers}
							users={users}
							handleAddUser={addUserToState}
						/>
						<RefreshToken />
					</React.Fragment>
				)
			}
		</React.Fragment>
	)
}

export default Home
