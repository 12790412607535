import React from 'react'
import PropTypes from 'prop-types'

const Tabs = ({ tabs, selected, onChange }) => {
	const handleChange = (event, index) => {
		event.preventDefault()
		event.stopPropagation()
		onChange(index)
	}

	return (
		(
			<div className="mb-3">
				<div className="sm:hidden">
					<label htmlFor="tabs" className="sr-only">Select a tab</label>
					<select
						id="tabs"
						name="tabs"
						className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
						onChange={
							event => onChange(Number(event.target.value))
						}
						value={selected.toString()}
					>
						{
							tabs.map((item, index) => (
								<option key={index} value={index.toString()}>{item}</option>
							))
						}
					</select>
				</div>
				<div className="hidden sm:block">
					<div className="border-b border-gray-200">
						<nav className="-mb-px flex space-x-8" aria-label="Tabs">
							{
								tabs.map((item, index) => (
									<a
										key={index}
										href="#"
										className={`${index === selected ? 'border-indigo-500 text-indigo-600' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'} whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm`}
										onClick={
											event => handleChange(event, index)
										}
									>
										{item}
									</a>
								))
							}
						</nav>
					</div>
				</div>
			</div>
		)
	)
}

Tabs.propTypes = {
	tabs: PropTypes.array,
	selected: PropTypes.number,
	onChange: PropTypes.func
}

export default Tabs
