import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import axios from 'axios'
import validate from 'validate.js'
import Notification from '../../components/Notification'
import NextGenAppsSignature from '../../components/NextGenAppsSignature'
import { setUserValue } from '../../store/actions/user'
import SPLaboratorijaLogo from '../../assets/img/logo-sp-laboratorija-small.png'
import HomepageBackground from '../../assets/img/homepage-background.jpeg'

const Login = ({ history }) => {
	const emptyFormData = {
		username: '',
		password: ''
	}

	const [formData, setFormData] = useState(emptyFormData)
	const [disableButton, setDisableButton] = useState(false)
	const [errors, setErrors] = useState([])

	const auth = useSelector(store => store.user.auth)
	const userData = useSelector(store => store.user.userData)

	const dispatch = useDispatch()

	const constraints = {
		username: {
			presence: {
				allowEmpty: false,
				message: 'Korisničko ime je obavezno.'
			}
		},
		password: {
			presence: {
				allowEmpty: false,
				message: 'Šifra je obavezna.'
			}
		}
	}

	useEffect(() => {
		if (auth && userData) {
			history.push('/reports')
		}
	}, [auth, userData])

	const login = event => {
		event.preventDefault()
		event.stopPropagation()
		const messages = validate(
			formData,
			constraints,
			{
				format: 'flat',
				fullMessages: false
			}
		)
		if (messages) {
			setErrors(messages)
		} else {
			setErrors([])
			setDisableButton(true)
			axios.post(`${process.env.REACT_APP_API_URL}/api/auth/login`, formData)
				.then(response => {
					console.log('response', response)
					axios.post(`${process.env.REACT_APP_API_URL}/api/auth/me`, null, {
						headers: {
							Authorization: `Bearer ${response.data.access_token}`
						}
					})
						.then(response2 => {
							dispatch(setUserValue('auth', response.data))
							dispatch(setUserValue('userData', response2.data))
							setDisableButton(false)
						})
						.catch(() => {
							setDisableButton(false)
						})
				})
				.catch(error => {
					setDisableButton(false)
					try {
						if (error.response.status === 401) {
							setErrors(['Pogrešno korisničko ime ili šifra.'])
							return
						}
						if (error.response.status === 403) {
							setErrors(error.response.data)
							return
						}
					} catch (error) {
						console.debug(error)
					}
					setErrors(['Dogodila se greška. Molimo pokušajte ponovo'])
				})
		}
	}

	const handleInputChange = (name, value) => {
		setFormData({
			...formData,
			[name]: value
		})
	}

	return (
		<div className="min-h-screen bg-white flex">
			<div className="relative flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
				<div className="mx-auto w-full max-w-sm lg:w-96">
					<div>
						<img
							className="h-12 w-auto"
							src={SPLaboratorijaLogo}
							alt="Workflow"
						/>
						<h2 className="mt-6 text-3xl font-extrabold text-gray-900">
							Izveštaji - SP Laboratorija
						</h2>
					</div>
					<div className="mt-8">
						<div className="mt-6">
							<form
								action="#"
								method="POST"
								className="space-y-6"
								onSubmit={login}
							>
								<div>
									<label
										htmlFor="username"
										className="block text-sm font-medium text-gray-700"
									>
										Korisničko ime
									</label>
									<div className="mt-1">
										<input
											id="username"
											name="username"
											type="text"
											value={formData.username}
											onChange={
												event => handleInputChange(event.target.name, event.target.value)
											}
											className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
										/>
									</div>
								</div>

								<div className="space-y-1">
									<label
										htmlFor="password"
										className="block text-sm font-medium text-gray-700"
									>
										Šifra
									</label>
									<div className="mt-1">
										<input
											id="password"
											name="password"
											type="password"
											value={formData.password}
											onChange={
												event => handleInputChange(event.target.name, event.target.value)
											}
											className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
										/>
									</div>
								</div>
								{
									errors.length > 0 && (
										<Notification
											type="error"
											title="Greška"
											handleClose={
												() => setErrors([])
											}
											message={
												errors.map((item, index) => (
													<React.Fragment key={index}>
														<span>{item}</span><br />
													</React.Fragment>
												))
											}
										/>
									)
								}
								<div>
									<button
										type="submit"
										className={`${disableButton ? 'cursor-not-allowed bg-gray-200 text-gray-400' : 'text-white bg-splab-800 hover:bg-splab-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'} w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium `}
										disabled={disableButton}
									>
										Prijava
									</button>
								</div>
							</form>
						</div>
					</div>
				</div>
				<div className="absolute bottom-2 left-0 right-0">
					<NextGenAppsSignature />
				</div>
			</div>
			<div className="hidden lg:block relative w-0 flex-1">
				<img
					className="absolute inset-0 h-full w-full object-cover"
					src={HomepageBackground}
					alt=""
				/>
			</div>
		</div>
	)
}

Login.propTypes = {
	history: PropTypes.object
}

export default withRouter(Login)
