import React from 'react'
import RefreshIcon from '../../assets/icons/RefreshIcon'

const Loader = () => (
	<div className="flex justify-center">
		<div className="animate-spin py-3">
			<RefreshIcon className="w-10 h-10" />
		</div>
	</div>
)

export default Loader
