import React from 'react'
import PropTypes from 'prop-types'
import CloseIcon from '../../assets/icons/CloseIcon'
import CheckCircleIcon from '../../assets/icons/CheckCircleIcon'
import InformationCircleIcon from '../../assets/icons/InformationCircleIcon'

const Notification = ({ type, icon, title, message, handleClose }) => {
	const getBackgroundClass = () => {
		if (type === 'error') {
			return 'bg-red-100'
		}
		return 'bg-green-100'
	}

	const getIcon = () => {
		if (icon) {
			return icon
		}
		if (type === 'error') {
			return <InformationCircleIcon />
		}
		return <CheckCircleIcon />
	}

	return (
		<div className={`${getBackgroundClass()} w-full rounded-xl`}>
			<div className="p-4">
				<div className="flex items-start">
					<div className="flex-shrink-0">
						{getIcon()}
					</div>
					<div className="ml-3 w-0 flex-1 pt-0.5">
						{
							title && (
								<p className="text-sm font-medium text-gray-900">
									{title}
								</p>
							)
						}
						<p className="mt-1 text-sm text-gray-500">
							{message}
						</p>
					</div>
					<div className="ml-4 flex-shrink-0 flex">
						{
							handleClose && (
								<button
									type="button"
									className="inline-flex text-gray-400 hover:text-gray-500 focus:outline-none"
									onClick={handleClose}
								>
									<span className="sr-only">Close</span>
									<CloseIcon />
								</button>
							)
						}
					</div>
				</div>
			</div>
		</div>
	)
}

Notification.propTypes = {
	type: PropTypes.string,
	icon: PropTypes.element,
	title: PropTypes.string,
	message: PropTypes.any,
	handleClose: PropTypes.func
}

export default Notification
